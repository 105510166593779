@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import '@design';
.container {
  height: 100%;
  width: 100%;
  background: #f8f8f8;
  margin: 0;
  padding: 0;
}
